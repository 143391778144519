export default function () {
  initMainMenu();
  initSubMenu();
  initMobileMenus();
}

function initMainMenu() {
  const subscriptionsMenu = document.querySelector(".js-menu-subs");
  const regionMenu = document.querySelector(".js-menu-regions");
  const regionDropdown = document.querySelector(".js-regions-dropdown");
  const closeDialog = document.querySelector(".js-dialog-close");
  const overlay = document.querySelector("#modal-bg");

  subscriptionsMenu?.addEventListener("click", function (event) {
    event.preventDefault();
    loginDialog.classList.add("hide");
    overlay.classList.add("show");
  });
  regionMenu?.addEventListener("click", (event) => {
    event.preventDefault();
    regionMenu.classList.toggle("active");
    regionDropdown.classList.toggle("active");
  });

  overlay?.addEventListener("click", function (event) {
    event.preventDefault();
    closeDialogAndRemoveOverlay();
  });
  closeDialog?.addEventListener("click", function (event) {
    closeDialogAndRemoveOverlay();
  });
}

function initSubMenu() {
  const sportsMenu = document.querySelector(".js-menu-sports");
  const sportsDropdown = document.querySelector(".js-sports-dropdown");
  sportsMenu?.addEventListener("click", function (event) {
    event.preventDefault();
    sportsMenu.classList.toggle("active");
    sportsDropdown.classList.toggle("active");
  });
}

function closeDialogAndRemoveOverlay() {
  const overlay = document.querySelector("#modal-bg");
  const loginDialog = document.querySelector("#login-dialog");
  overlay.classList.remove("show");
  loginDialog.classList.add("hide");
}

const initMobileMenus = () => {
  const ACTIVE_CLASS = "active-menu";
  const menuContainer = document.getElementById("mobile-menu");
  if (!menuContainer) {
    return;
  }
  const menuTrigger = document.getElementById("js-mobile-menu-trigger__menu");
  const menuDropdown = document.getElementById("js-mobile-dropdown__menu");
  const subsTrigger = document.getElementById("js-mobile-menu-trigger__subs");
  const subsDropdown = document.getElementById("js-mobile-dropdown__subs");

  menuTrigger.addEventListener("click", (e) => {
    e.preventDefault();
    subsDropdown.classList.remove(ACTIVE_CLASS);
    subsTrigger
      .closest(".mobile__navigation__toplevel")
      .classList.remove(ACTIVE_CLASS);
    menuDropdown.classList.toggle(ACTIVE_CLASS);
    menuTrigger
      .closest(".mobile__navigation__toplevel")
      .classList.toggle(ACTIVE_CLASS);
  });
  subsTrigger.addEventListener("click", (e) => {
    e.preventDefault();
    menuDropdown.classList.remove(ACTIVE_CLASS);
    menuTrigger
      .closest(".mobile__navigation__toplevel")
      .classList.remove(ACTIVE_CLASS);
    subsDropdown.classList.toggle(ACTIVE_CLASS);
    subsTrigger
      .closest(".mobile__navigation__toplevel")
      .classList.toggle(ACTIVE_CLASS);
  });
};
