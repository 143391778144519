
import menu from './modules/menu';
import reveal from './modules/reveal';
console.log('app.js loaded!');


	menu();
	reveal();


