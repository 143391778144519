

let trigger;
let hiddenField;

export default function init () {
    trigger = document.querySelector('.js-hidden-field-trigger');
    hiddenField = document.querySelector('.js-hidden-field');
    if (!trigger) {
        return;
    }
    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        hiddenField.classList.add('show');
        trigger.style.display = 'none';
    })
}